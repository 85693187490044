<div class="settings-container text-center">
 <app-logo></app-logo>
  <div class="settings text-left">
    <h1>{{'settings-page.settings' | translate | titlecase}}</h1>
    <h3>{{'settings-page.language' | translate | translate}}</h3>
    <div class="select-language">
      <select class="form-select" [ngModel]="currentLanguage" (ngModelChange)="onSelectChange($event)">
        <option *ngFor="let lang of langCodes"
                [selected]="lang === currentLanguage"
                [ngValue]="lang"
        >
          {{getLangName(lang).nativeName | titlecase}}
        </option>
      </select>
      <div class="change">{{'settings-page.change' | translate | uppercase}}</div>
    </div>
  </div>
  <div class="version text-center">
    V.1.1.1
    <br/>
    {{'settings-page.code' | translate | uppercase}} 2.1.2
  </div>
</div>
