<div class="container-fluid" *ngIf="section">
    <div class="detail-image-display">
        <img class="detail-img" [src]="section.list_page_image.original">
    </div>
    <div *ngIf="section?.list_page_image.source">
       <p class="img-source">Source: {{section?.list_page_image.source}}</p>
    </div>
    <div class="detail-title">
        <p>{{section.name}}</p>
    </div>
    <div class="detail-description" >
        <!-- <p class="subtitle">Description</p> -->
        <div class="description-box" [ngClass]="{'collapsed': !showMore}">
            <p  [innerHTML]="descriptionText" ></p>
            
        </div>
        <div class="gradient-box"></div>
        <div (click)="toggleReadMore()" class="showMore">
            <a class="showMoreButton">{{readText}}</a>
        </div>
        <!-- <p [innerHTML]="section.description"></p> -->
    </div>
    <div style="margin-bottom: 160px">
        <!-- <h3>Items</h3> -->
        <!-- <div> -->
            <div *ngFor="let mx of section.items" class="item-listing" (click)="goToItem(mx.id)">
                <div class="item-image col-auto">
                    <img [src]="mx.image.original" />
                    <div class="item-id rounded-circle" *ngIf="mx?.map_item_id">
                        <div>
                          {{mx.map_item_id}}
                        </div>
                      </div>
                </div>
                <div class="col">
                    <span>{{mx.name}}</span>
                </div>
            </div>
        <!-- </div> -->
    </div>
</div>
