<div class="container-fluid h-100" *ngIf="tourObj">
  <app-item-details [stop]="getItem()" *ngIf="!showNextSteps"></app-item-details>
  <div *ngIf="showNextSteps">
    <h1 style="text-align: center; font-size: 1.525em">{{'tour-stop-page.directions' | translate | titlecase}}</h1>
    <!-- <div class="col">
      <h2>Where Do I Go?</h2>
    </div> -->
    <br/>
    <br/>
    <div class="col next-stop">
      <p class="next-stop-name"><b>{{'tour-stop-page.next-stop' | translate | titlecase}}:</b> {{tourStop.go_to_this_stop}}</p>
      <p class="next-stop-directions" [innerHTML]="tourStop.get_to_next_item"></p>
    </div>
    <div class="stop-header col" style="height: 400px; width: 100vw">
      <!-- <pinch-zoom [zoomControlScale]="0"> -->
        <img [src]="getTourMapImage()" />
        <!-- </pinch-zoom> -->
        
        
      <!-- <iframe [src]="getTourMapImage()" style="width: 100%;" frameborder="0"></iframe> -->
      <!-- <img class="map_image" [src]="tourStop.tour_map.square_retina"/> -->
    </div>
  </div>
  <div class="row button-container">
    <!--<button class="prev-stop" [disabled]="isFirstStop()" [ngClass]="isFirstStop() && 'disabled-button'"-->
    <button class="prev-stop"
            (click)="prevStop()">
      <!--Previous Stop-->
      <span *ngIf="isFirstStop()">
        {{'tour-stop-page.back-to-list' | translate | titlecase}}
      </span>
      <span *ngIf="!isFirstStop()">
        {{'tour-stop-page.prev-stop' | translate | titlecase}}
      </span>
    </button>
    <button class="next-stop"
            *ngIf="!isLastStop()"
            [ngClass]="isLastStop() && 'disabled-button'"
            (click)="nextStop()">
      {{'tour-stop-page.next-stop' | translate | titlecase}}
    </button>
    <button class="next-stop"
            *ngIf="isLastStop()"
            (click)="backToList()">
      {{'tour-stop-page.back-to-list' | translate | titlecase}}
    </button>
  </div>
</div>


