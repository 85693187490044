
<div class="tour-container container-fluid" *ngIf="tours?.length">
  <h3>{{text | uppercase}}</h3>
  <div *ngIf="tours?.length">
    <div *ngFor="let tour of tours; let i = index" class="row tour-item" (click)="goToTour(tour.id)">
      <img class="img-fluid w-100" src="{{getTourImgUrl(tour.id)}}" alt="{{getTourInfo(tour.id)}}"/>
      <span class="tour-info w-100">{{getTourInfo(tour.id) | titlecase}}</span>
    </div>
  </div>

  <div class="spacer" style="min-height: 15vh"></div>
</div>
