<div class="calendar-container">
  <app-logo></app-logo>
  <div>
   <div class="text-center">
     <ngb-datepicker *ngIf="showCalendar" [outsideDays]="'collapsed'" class="custom-datepicker" #d [dayTemplate]="customDay" [navigation]="'arrows'" (navigate)="changeMonth($event)" [(ngModel)]="selectedDay"></ngb-datepicker>
     <ng-template
       #customDay
       let-date
       let-currentMonth="currentMonth"
       let-selected="selected"
     >
	<span
    class="custom-day"
    [class.with-events]="withEvents(date)"
    [class.selected]="selected"
    [class.abc]="date.month > currentMonth"
    [class.hidden]="date.month !== currentMonth"
  >
		{{ date.day }}
	</span>
     </ng-template>
   </div>
    <div *ngIf="selectedDay" class="w-100">
      <div *ngFor="let item of filterItems(); let i = index" class="row tour-item">
        <div *ngIf="item.type === 'tour'" class="w-100" (click)="goToTour(item.tour.id)">
          <img class="img-fluid w-100" src="{{getTourImgUrl(item.tour.id)}}" />
          <p class="w-100 mb-0">
            {{item.start_date | date: 'short' }} - {{item.end_date| date: 'short' }}
          </p>
          <span class="tour-info w-100">{{getTourInfo(item.tour.id) | titlecase}}</span>
        </div>
        <div *ngIf="item.type === 'event'" class="w-100" (click)="goToEvent(item.event.id)">
          <img class="img-fluid w-100" src="{{getEventImgUrl(item.event.id)}}"/>
          <p class="w-100 mb-0">
            {{item.start_time | date:'short' }} -
            {{item.end_time | date:'short'}}
          </p>
          <span class="activity-info w-100">{{item.event.name}}</span>
        </div>
      </div>
      <div class="w-100" *ngIf="filterItems().length<=0">
        <p>
          {{'calendar.no-events' | translate | uppercase}}
        </p>
      </div>
    </div>
  </div>
  <div class="spacer" style="min-height: 15vh"></div>
</div>
