<div class="container-fluid h-100" *ngIf="item">
  <div class="stop-header col-12"
       [style.background-image]="'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #F5F5F5 97.92%), url(' + item.image.full + ')'">
  </div>
  <div *ngIf="item?.image.source">
    <p class="img-source">Source: {{item?.image.source}}</p>

  </div>
  <div class="row header-row" style="margin:0;" *ngIf="item?.map_item_id">
    <div class="col">
      <h1 class="header-title">{{getName()}}</h1>
    </div>
    <div class="col-auto">
      <div class="map-item-indicator">
        <div class="map-item-circle">
          <div>
            {{item.map_item_id}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="stop-audio" *ngIf="state.canplay">
    <button class="play-button" (click)="play()" *ngIf="!state.playing"></button>
    <button class="pause-button" (click)="pause()" *ngIf="state.playing"></button>
    <mat-slider class="slider" [min]="0"
                [max]="state ? state.duration : 0"
                [step]="1"
                (change)="onSliderChangeEnd($event)"
                [value]="state?.currentTime"
    >
    </mat-slider>
    <div class="duration">{{state.readableCurrentTime}} / {{state ? state.readableDuration : "00:00"}}</div>
  </div>
  <div class="feature-buttons" *ngIf="hasFeatures()">
    <button (click)="toggleGallery()" *ngIf="item?.photos?.length" class="mr-4"><i class="far fa-images" [style]="showGallery ? 'color: var(--primary-color)' : 'color: black'" ></i></button>
    <button (click)="toggleVideos()" *ngIf="item?.videos?.length"><i class="fab fa-youtube" [style]="showVideos ? 'color: var(--primary-color)' : 'color: black'"></i></button>
  </div>
  <app-image-gallery
    [text]="'image-gallery.images' | translate"
    [photoObj]="item?.photos"
    [show]="showGallery"
    *ngIf="item?.photos?.length">
  </app-image-gallery>
  <app-image-gallery
    [text]="'image-gallery.videos' | translate"
    [videoObj]="item?.videos"
    [show]="showVideos"
    *ngIf="item?.videos?.length">
  </app-image-gallery>

  <div class="stop-content-section">
    <div class="stop-description col-12" [innerHTML]="item.description" [dir]="rtl? 'rtl' : 'ltr'" [ngClass]="rtl? 'text-right' : ''"></div>
  </div>
  <div class="spacer"></div>
</div>
