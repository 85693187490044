<div class="container-fluid search-container">
  <div class="input-wrapper">
    <img src="assets/img/keypad-icon.svg" class="keypad-search-icon"
         [class.toggle-icon]="typing"
    >
    <input [type]="searchType" class="col-12 search-tour" s
           [attr.placeholder]="translationKey | translate"
           #searchTerm
           (input)="searchTours(searchTerm.value)">
  </div>
  <h1>{{'search-tour-page.search-results' | translate | titlecase}}</h1>
  <app-item-list
    [text]="translationKey | translate"
    [query]="query"
    [search_fields]="appDetails?.web_app_item_search_fields">

  </app-item-list>
</div>
