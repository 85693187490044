import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
// import { GuruApiEventService } from '../guru-api/guru-api-events.service';
import { LoadingService } from '../services/loading/loading.service';
import { BaseApiService } from '../guru-api/base/base-api.service';
// import { GuruApiInfoService } from '../guru-api/guru-api-info.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
// import { GuruApiDineGiftService } from '../guru-api/guru-api-dinegift.service';
import { GuruApiMapService } from '../guru-api/guru-api-map.service';
// import { GuruApiNewsService } from '../guru-api/guru-api-news.service';

@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss']
})
export class DetailPageComponent implements OnInit {
  detailId: number;
  detailType: string;
  def: object;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private guruApiAppService: GuruApiAppService,
    // private guruApiInfoService: GuruApiInfoService,
    // private guruApiDineGiftService: GuruApiDineGiftService,
    private guruApiSectionService: GuruApiMapService,
    // private guruNewsService: GuruApiNewsService,
    private router: Router,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadingService.appState.subscribe(state => {
      if (state) {
        
        this.route.paramMap.subscribe(params => {
          this.detailId = Number(params.get('id'));
          this.detailType = String(params.get('kind'))
          // this.tourStopIndex = Number(params.get('tourStopIndex'));
          this.loadData();
          
        });
      }
    })
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);

}
  loadData() {
    this.loadingService.show()
    switch (this.detailType) {
      // case "info": {
      //   const info = this.guruApiInfoService.getInfo(this.detailId)
      //   if (info) {
      //     this.def = info;
      //   } else {
      //     this.guruApiInfoService.loadInfos()
      //     .pipe(takeUntil(this.unsubscribe$))
      //     .subscribe(
      //       data => {
      //         this.guruApiInfoService.setInfos(data.objects);
      //         const info = this.guruApiInfoService.getInfo(this.detailId);
      //         console.log(info);
      //         this.def = info;
      //       }
      //     )
      //   }
      //   break;
      // }
      // case "dining": {
      //   const dining = this.guruApiDineGiftService.getOption(this.detailId)
      //   if (dining) {
      //     this.def = dining;
          
      //   } else {
      //     this.guruApiDineGiftService.loadOptions()
      //     .pipe(takeUntil(this.unsubscribe$))
      //     .subscribe(
      //       data => {
      //         this.guruApiDineGiftService.setOptions(data.objects);
      //         const dining = this.guruApiDineGiftService.getOption(this.detailId);
      //         console.log(dining);
      //         this.def = dining;
      //       }
      //     )
      //   }
      //   break;
      // }
      case "section": {
        const section = this.guruApiSectionService.getMapItem(this.detailId);
        // console.log(section);
        if (section && section.items) {
          // console.log(section.items)

            
            this.def = section;
            
          
         
        } else {
          this.guruApiSectionService.loadMapItemDetail(this.detailId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            data => {
              // console.log("data: ", data);
              this.guruApiSectionService.setMapItem(data);
              const section = this.guruApiSectionService.getMapItem(this.detailId);
              // console.log("sec: ", section);
              this.def = section;
            }
          )
        }
        break;
      }
      // case "news": {
      //   const news = this.guruNewsService.getNewsItem(this.detailId);
      //   if (news) {
      //     this.def = news;
          
      //   } else {
      //     this.guruNewsService.loadNews()
      //     .pipe(takeUntil(this.unsubscribe$))
      //     .subscribe(
      //       data => {
      //         this.guruNewsService.setNews(data.objects)
      //         this.def = this.guruNewsService.getNewsItem(this.detailId)
      //       }
      //     )
      //   }
      // }
    }
    this.loadingService.hide()

  }

}
