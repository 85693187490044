<div class="map-container" *ngIf="map_items?.length > 1 && !isCVC()">
  
    <h1 class="text-center">{{"maps" | uppercase}}</h1>
    <div class="row map-items" *ngFor="let map of map_items; let i = index" (click)="goToMap(i)">
      <span class="w-100">{{map.name | uppercase}}</span>
      <img src="{{getMapUrl(i)}}" alt="{{map.name}}" class="img-fluid w-100" >
    </div>

  <div class="spacer"></div>
</div>
<div class="map-container" *ngIf="isCVC()">
  <!-- <span>map</span> -->
  <app-arcmap [points]="sitePoints" (pointClick)="pointClick($event)"  [externalSelect]="selectedPoint"></app-arcmap>
  <div id="scrollbox">
    <div *ngFor="let mx of mapListings()" class="map_listing" [id]="mx.order">
      <div class="content" (click)="listingClick(mx.id)" >
        <div class="col">
          <img [src]="mx.list_page_image.original" class="map-image"/>
        </div>
        <div class="col">
         <div class="title">
          {{mx.order}}: {{mx.name}}
         </div>
     
        </div>
      
      </div>
      <div *ngIf="isolatedListing > -1" class="item-list" >
        <hr class="inner-divider"/>
        <!-- {{mx.items}} -->
        <div *ngFor="let subItem of mx.items;" class="row" (click)="goToItem(subItem.id)">
          <div class="col-auto">
            <img class="mini-image" [src]="subItem.image.thumb_manager"/>
            <div class="image-badge">
              <span>{{subItem.map_item_id}}</span>
            </div>
          </div>
          <div class="col">
            <span>{{subItem.name}}</span>
          </div>
        </div>
       </div>
      <!-- <div class="button-box" *ngIf="isolatedListing > -1">
        <ion-button (click)="isolatedSeeMore()">Learn More</ion-button>
        <ion-button (click)="isolatedGoHere()">Go Here</ion-button>
      </div> -->
    </div>
  </div>
  <div class="spacer"></div>
</div>