<div class="today-activities-container">
  <app-logo></app-logo>
  <h3>
    {{'todays-activities.title' | translate | uppercase}}
  </h3>
  <div *ngFor="let activity of activities; let i = index" class="row activity-item" (click)="goToEvent(activity.event.id)">
    <img class="img-fluid w-100" src="{{getActivityImgUrl(activity.event.id)}}"/>
    <p class="w-100 mb-0">
      {{activity.start_time | date:'shortTime' }} -
      {{activity.end_time | date:'shortTime'}}
    </p>
    <span class="activity-info w-100">{{activity.event.name}}</span>
  </div>
  <div class="spacer" style="min-height: 15vh"></div>
</div>
