import { Component, Input, OnInit } from '@angular/core';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { Subject } from 'rxjs';
import { CategoryModel } from '../guru-api/models/category.model';
import { GuruApiCategoryService } from '../guru-api/guru-api-category.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-children-list',
  templateUrl: './category-children-list.component.html',
  styleUrls: ['./category-children-list.component.scss'],
  animations: [
    trigger(
      'inAndOutAnimation',
      [
        transition(
          ':enter',
          [
            style({
              height: '0',
              opacity: '0'
            }),
            animate('.5s ease-out',
              style({
                height: '100%',
                opacity: '1'
              }))
          ]
        ),
        transition(
          ':leave',
          [
            style({
              height: '100%',
              opacity: '1'
            }),
            animate('.5s ease-in',
              style({
                height: '0',
                opacity: '0'
              }))
          ]
        )
      ]
    )
  ]
})
export class CategoryChildrenListComponent implements OnInit {
  @Input() text?: string;
  @Input() items: CategoryModel[];

  range: number;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToCategory(id: number): void{
    this.router.navigate(['category/' + id]);
  }

}
