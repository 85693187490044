import {Component, OnInit, OnDestroy} from '@angular/core';
import {GuruApiAppService} from '../guru-api/guru-api-app.service';
import {AppDetailsModel} from '../guru-api/models/app-details.model';
import {Subscription} from 'rxjs';
import {AppConfigService} from '../services/app-config/app-config.service';
import {CookieService} from 'ngx-cookie-service';
import { LoadingService } from '../services/loading/loading.service';

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss']
})
export class LanguagePageComponent implements OnInit, OnDestroy {
  selected: string;
  langCodes = ['en'];
  appDetails: AppDetailsModel;
  appDetailsSubscription$: Subscription;
  loadingServiceSubscription$: Subscription;
  isLoading = true;

  constructor(private guruApiAppService: GuruApiAppService,
              private  appConfigService: AppConfigService,
              private cookieService: CookieService,
              private loadingService: LoadingService) {
    this.loadingService.appState.subscribe(state =>{
      if(state){
        this.lodaAppDetails();
      }
    })
  }

  ngOnInit(): void {
    if(this.loadingService.appState.value){
      this.lodaAppDetails();
    }
  }
  ngOnDestroy(): void{
    if(this.appDetailsSubscription$) {
      this.appDetailsSubscription$.unsubscribe();
    }
    if(this.loadingServiceSubscription$){
      this.loadingServiceSubscription$.unsubscribe();
    }
  }

  getLangName(code: string): string{
    return this.guruApiAppService.getLangName(code);
  }

  onSelect(language: string): void {
    this.selected = language;
    this.cookieService.set('language', this.selected);
    this.appConfigService.language = this.selected;
  }

  initLanguage(appDetails: AppDetailsModel): void{
    this.langCodes = [...this.langCodes, ...appDetails.languages];
    this.langCodes = [...new Set(this.langCodes)];
    this.appConfigService.setLanguages(this.langCodes);
    if(this.cookieService.check('language')) {
      this.selected = this.cookieService.get('language');
    } else {
      this.selected = this.langCodes[0];
      this.cookieService.set('language', this.selected);
    }
  }

  lodaAppDetails(): void{
    const appDetails = this.guruApiAppService.getAppDetails();
    if(appDetails){
      this.initLanguage(appDetails);
      this.isLoading = false;
      return;
    }

    this.appDetailsSubscription$ = this.guruApiAppService.loadAppDetails().subscribe(
      data => {
        this.initLanguage(data.objects[0]);
        this.isLoading = false;
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
      }
    );
  }
}
