import {Injectable, Injector} from '@angular/core';
import {TourModel} from './models/tour.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiTourTimesService extends BaseApiService {
  tourTimes: object = {};
  tourTimesList: TourModel[] = [];

  constructor(private injector: Injector) {
    super(injector, 'tour_time/');
  }

  loadTourTimes(tour_date_month: number, tour_date_year: number ): Observable<ApiListResponseModel<any>> {
    console.log("load tour times 2");
    return super.getList<any>({
      include_in_webapp: 1,
      tour_date_month: tour_date_month,
      tour_date_year: tour_date_year,
      show_on_calendar: true,
    });
  }


  getTourTimes(): any[] {
    return this.tourTimesList;
  }

  setTourTimes(tourTimes: any[]): void{
    this.tourTimesList = tourTimes;
  }

  resetTourTimes(): void{
    this.tourTimesList = []
    this.tourTimes = {};
  }
}
