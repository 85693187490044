import { Injectable, Injector } from '@angular/core';
import { AppDetailsModel } from './models/app-details.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseApiService } from './base/base-api.service';
import { AppAboutModel } from './models/app-about.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiAboutService extends BaseApiService{
  appAbout: AppAboutModel;

  constructor(private injector: Injector) {
    super(injector, 'app/', '/about/');
  }

  getAppAbout(): AppAboutModel {
    return this.appAbout;
  }

  setAppAbout(aboutInfo: AppAboutModel): void{
    this.appAbout = aboutInfo;
  }

  loadAppAbout(): Observable<AppAboutModel> {
    return super.getDetail<AppAboutModel>(environment.appId);
  }
}
