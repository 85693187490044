import {Injectable, Injector} from '@angular/core';
import {BaseApiService} from './base/base-api.service';
import {Observable} from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';
import {SiteSectionModel} from './models/site-section.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiMapService extends BaseApiService {
  site_section: SiteSectionModel[];
  sections: object = {};

  constructor(private injector: Injector) {
    super(injector, 'site_section/');
  }

  loadMapItems(webAppOnly: boolean = false): Observable<ApiListResponseModel<SiteSectionModel>> {
    return super.getList<SiteSectionModel>({for_web_app_only: webAppOnly? '1': '0'});
  }
  getMapItems(): SiteSectionModel[]{
    return this.site_section;
  }

  setMapItems(maps: SiteSectionModel[]): void{
    this.site_section = maps;
  }

  getMapItem(id: number): SiteSectionModel {
    return this.sections[id];
  }
  loadMapItemDetail(id: number): Observable<SiteSectionModel> {
    return super.getDetail<SiteSectionModel>(id)
  }
  setMapItem(mx: SiteSectionModel) {
    this.sections[mx.id] = mx;
    this.site_section = (Object.values(this.sections))
    // this.site_section.
  }
  clearData(): void {
    this.site_section = null;
    this.sections = {};
  }
}
