import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { StreamState } from '../services/audio/stream-state';
import { pipe, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AudioService } from '../services/audio/audio.service';
import { GuruApiTourItemsService } from '../guru-api/guru-api-item.service';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import {GuruApiAppService} from "../guru-api/guru-api-app.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() stop?: TourItemModel;
  itemId?: number;
  itemObj: TourItemModel;
  state: StreamState;
  showGallery = false;
  showVideos = false;
  unsubscribe$: Subject<void> = new Subject<void>();

  traceTimeSpent: any;


  set item(value: TourItemModel) {
    // console.log("new item value: ", value)
    this.itemObj = value;
    // console.log(this.audioService.tourItemId !== this.item.id)
    // if (this.audioService.tourItemId !== this.item.id) {
      if (this.item.audio) {
        this.audioService.prepareStream(this.item.audio.audio).subscribe(events => {
        });
        this.audioService.tourItemId = this.item.id;
      }
    // }
  }

  get item(): TourItemModel {
    return this.itemObj;
  }

  rtl: boolean = false;
  constructor(private route: ActivatedRoute,
              private audioService: AudioService,
              private guruApiItemService: GuruApiTourItemsService,
              private guruFirebaseService: GuruFirebaseService,
              private loadingService: LoadingService,
              private cookieService: CookieService,
              private guruApiAppService: GuruApiAppService
  ) {
    this.audioService.resetState();
    this.audioService.getState().subscribe(state => {
      this.state = state;
    });
  }

  ngOnInit(): void {
    const langDetails = this.guruApiAppService.getLangName(this.cookieService.get('language'));
    this.rtl = !!langDetails['rtl'];

    this.loadingService.appState.subscribe(state => {
      if (state) {
        this.traceTimeSpent = this.guruFirebaseService.performance.trace('Time Spent On Item Details Page');
        this.traceTimeSpent.start();
        if (!this.stop) {
          this.route.paramMap
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(params => {
              if (this.item && this.audioService.tourItemId !== this.item.id) {
                this.audioService.stop();
                this.audioService.resetState();
              }

              this.itemId = Number(params.get('itemId'));
              this.loadItem();
            });
        } else {
          if (this.stop && this.audioService.tourItemId !== this.stop.id) {
            this.audioService.stop();
            this.audioService.resetState();
          }
          this.item = this.stop;
        }
      }
    }, error => {
      console.log('error', error);
    })
  }

  ngOnChanges(): void {
    if (this.stop) {
      this.audioService.stop();
      this.audioService.resetState();
      this.item = this.stop;
    }
  }

  ngOnDestroy(): void {
    this.traceTimeSpent.stop();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.audioService) {
      console.log(this.audioService)
      this.audioService.stop();
      this.audioService.resetState();
    }
  }

  loadItem(): void {
    const traceItemDetailsLoad = this.guruFirebaseService.performance.trace('Item Details Load')
    traceItemDetailsLoad.start();

    this.guruApiItemService.loadTourItem(this.itemId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      data => {
        this.item = data;
        this.guruFirebaseService.logVisitTourItemPage(this.item);
        this.traceTimeSpent.putAttribute('Name', this.item.name);
        traceItemDetailsLoad.putAttribute('Name', this.item.name);
        traceItemDetailsLoad.stop();
      },
      error => {
        if (error.error.detail === 'Invalid page.') {
          console.log('error', error);
        }
        traceItemDetailsLoad.stop();
      });
  }

  getName(): string {
    return this.item.name;
  }

  play(): void {
    if (this.state.canplay) {
      this.audioService.play();
    } else {
      this.audioService.playStream(this.item.audio.audio).subscribe(events => {
      });
    }
  }

  pause(): void {
    this.audioService.pause();
  }

  onSliderChangeEnd(change): void {
    this.audioService.seekTo(change.value);
  }

  toggleGallery(): void{
    this.showVideos = false;
    this.showGallery = !this.showGallery;
  }
  toggleVideos(): void{
    this.showGallery = false;
    this.showVideos = !this.showVideos;
  }
  hasFeatures(): boolean {
    return this.item?.photos?.length > 0 || this.item?.videos?.length > 0
  }
}
