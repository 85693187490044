import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CategoryModel } from '../guru-api/models/category.model';
import { GuruApiCategoryService } from '../guru-api/guru-api-category.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnDestroy {
  @Input() text = 'categories';
  categories: CategoryModel[];
  subCategories: CategoryModel[] = [];

  showSubCategories: [number, boolean] = [-1, false];

  unsubscribe$: Subject<void> = new Subject<void>();
  constructor(private categoryService: GuruApiCategoryService,
              private loadingService: LoadingService,
              private router: Router) { }

  ngOnInit(): void {
      this.loadingService.loaderState
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(state => {
          if (!state.show && (!this.categories && !this.categories?.length)) {
            this.loadCategories();
          }
        });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadCategories(): void{
    const categories = this.categoryService.getCategories();
    if(categories && categories.length){
      this.categories = categories.filter(c => c?.include_in_webapp);
      return;
    }

    this.categoryService.loadCategories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.categories = data.objects.filter(c => c?.include_in_webapp);
        this.categoryService.setCategories(this.categories);
      }, error => {
        console.log('error', error);
      });
  }

  goToCategory(id: number, index: number): void{
    if(this.categories[index].has_children){
      if(this.showSubCategories[0] === index && this.showSubCategories[1]){
        this.showSubCategories[1] = false;
        return;
      }

      if(this.showSubCategories[0] !== index || !this.showSubCategories[1]){
        this.showSubCategories[1] = false;
        this.categoryService.loadCategoryChildren(id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.subCategories = data;
            this.showSubCategories = [index, true];
          }, error => {
            console.log('error', error);
          })
      }
    } else {
      this.router.navigate(['/category/' + id])
    }
    return;
  }

}
