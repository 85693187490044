import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuruApiToursService } from '../guru-api/guru-api-tours.service';
import { Subscription } from 'rxjs';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { GuruApiTourItemsService } from '../guru-api/guru-api-item.service';
import { TourModel } from '../guru-api/models/tour.model';
import { TourStopModel } from '../guru-api/models/tour-stop.model';
import { AudioService } from '../services/audio/audio.service';
import { StreamState } from '../services/audio/stream-state';
import { Location } from '@angular/common';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';
import { LoadingService } from '../services/loading/loading.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tour-stop-page',
  templateUrl: './tour-stop-page.component.html',
  styleUrls: ['./tour-stop-page.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class TourStopPageComponent implements OnInit, OnDestroy {
  tourId: number;
  tourStopIndex: number;
  tourObj: TourModel;
  tourStop: TourStopModel;
  tourSubscription$: Subscription;
  showNextSteps: boolean = false
  nextStepsClicked: boolean = false
  traceTimeSpent: any;

  set tour(tModel: TourModel) {
    this.tourObj = tModel;
    this.tourStop = tModel.tour_items[this.tourStopIndex];
  }

  get tour(): TourModel {
    return this.tourObj;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private guruApiToursService: GuruApiToursService,
              private guruFirebaseService: GuruFirebaseService,
              private loadingService: LoadingService,
              private sanitizer: DomSanitizer
  ) {
    this.route.url.subscribe((val) => {
      this.nextStepsClicked = false;
      this.showNextSteps = false
    })
  }

  ngOnInit(): void {
    this.loadingService.appState.subscribe(state => {
      if (state) {
        this.traceTimeSpent = this.guruFirebaseService.performance.trace('Time Spent On Tour Stop Page');
        this.traceTimeSpent.start();
        this.route.paramMap.subscribe(params => {
          this.tourId = Number(params.get('tourId'));
          this.tourStopIndex = Number(params.get('tourStopIndex'));
          this.loadData();
        });
      }
    })
  }

  ngOnDestroy(): void {
    if (this.tourSubscription$) {
      this.tourSubscription$.unsubscribe();
    }
  }

  loadData(): void {
    const traceTourStopLoad = this.guruFirebaseService.performance.trace('Tour Stop Load');
    traceTourStopLoad.start();
    const tour = this.guruApiToursService.getTour(this.tourId);
    if (tour) {
      this.tour = tour;
      this.guruFirebaseService.logVisitTourStopPage(this.getItem());
      this.traceTimeSpent.putAttribute('Name', this.tour.name);
      traceTourStopLoad.putAttribute('Name', this.tour.name);
      traceTourStopLoad.stop();
      return;
    }

    this.tourSubscription$ = this.guruApiToursService.loadTour(this.tourId).subscribe(
      (data: TourModel) => {
        this.tour = data;
        console.log(this.tour)

        this.guruFirebaseService.logVisitTourStopPage(this.getItem());
        traceTourStopLoad.putAttribute('Name', this.tour.name);
        traceTourStopLoad.stop();
      }, error => {
        console.log('error', error);
        traceTourStopLoad.stop();
      });
  }

  prevStop(): void {
    this.nextStepsClicked = false
    if (this.isFirstStop()) {
      this.router.navigate(['tour/' + this.tour.id + '/']);
    } else {
      this.router.navigate(['tourStop/' + this.tour.id + '/' + (this.tourStopIndex - 1)]);
    }
  }

  nextStop(): void {
    if (this.hasDirectional() && !this.nextStepsClicked && !this.showNextSteps) {
      this.showNextSteps = true;
      return
    }
    if (this.showNextSteps && !this.nextStepsClicked) {
      this.showNextSteps = false
      this.nextStepsClicked = true
    }
    this.router.navigate(['tourStop/' + this.tour.id + '/' + (this.tourStopIndex + 1)]);
  }

  hasDirectional(): boolean {
    // console.log(this.tourStop.tour_map)
    // console.log(this.tourObj.ui_type == "item-directions-item-tour" && (this.tourStop.get_to_next_item != "" || this.tourStop.tour_map !== undefined))
    return this.tourObj.ui_type == "item-directions-item-tour" && ((this.tourStop.get_to_next_item != "" && typeof this.tourStop.get_to_next_item != "undefined") || this.tourStop.tour_map !== undefined)
  }
  isFirstStop(): boolean {
    return this.tourStopIndex === 0;
  }

  getItem(): TourItemModel {
    return this.tourObj.tour_items[this.tourStopIndex].item;
  }

  isLastStop(): boolean {
    return this.tourStopIndex + 1 === this.tour.tour_items.length;
  }

  backToList() {
    this.router.navigate(['tour/' + this.tour.id + '/']);
  }
  getTourMapImage() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.tourStop.tour_map.original)
  }

}
