import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {GuruApiMapService} from '../guru-api/guru-api-map.service';
import {SiteSectionModel, LocationDisplay} from '../guru-api/models/site-section.model';
import {ActivatedRoute, Router} from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { TourModel } from '../guru-api/models/tour.model';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-map-list',
  templateUrl: './map-list.component.html',
  styleUrls: ['./map-list.component.scss']
})
export class MapListComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  map_items?: SiteSectionModel[];
  sitePoints?: LocationDisplay[] = [];
  // navPoints?: NavStep[]
  inRange?: boolean;
  isolatedListing?: number;
  selectedPoint: LocationDisplay;
  map_display_items?: SiteSectionModel[];
  lastBackInterceptCount: number = 0;
  constructor(private guruApiMapService: GuruApiMapService,
              private router: Router,
              private loadingService: LoadingService,
              private route: ActivatedRoute) {
                
  }

  ngOnInit(): void {
    this.loadingService.appState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( state => {
        if (state){
          this.loadData();
        }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  mapListings(): SiteSectionModel[] {
    if (this.isolatedListing > -1) {
      return this.map_items.filter((vx) => {
        return vx.order == this.isolatedListing;
      })
    }
    return this.map_items
  }
  loadData(): void {
    if (!this.isCVC()) {
      // const data = this.guruApiMapService.getMapItems();
      // console.log(data)
      // if (data?.length){
  
      //   this.map_items = data.filter(ss => ss?.for_web_app_only);
  
      //   if (!this.isCVC()){
      //     console.log("goto map")
          this.goToMap(0, '-s');
        // }
      //   return;
      // }
    }


    this.guruApiMapService.loadMapItems()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      (d) => {
        this.map_items = d.objects;
        this.guruApiMapService.setMapItems(this.map_items);
        if (this.map_items.length === 1 && !this.isCVC()){
          this.goToMap(0, '-s');
        }
        this.sitePoints = new Array<LocationDisplay>();
        this.map_items.forEach((mx) => {
          this.guruApiMapService.loadMapItemDetail(mx.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (d) => {
              d.locationDisplay = {
                latitude: parseFloat(d.latitude),
                longitude: parseFloat(d.longitude),
                title: d.name,
                id: d.order,
              }
              d.items?.map((ix) => {
                if (ix.locations.length > 0) {
                  const primeLoc = ix.locations[0]
                  ix.locationDisplay = {
                    latitude: parseFloat(primeLoc.latitude),
                    longitude: parseFloat(primeLoc.longitude),
                    title: ix.name,
                    id: ix.order_num,
                  }
                }
              })
              console.log(d)
              this.guruApiMapService.setMapItem(d)
              this.map_items = this.guruApiMapService.getMapItems().sort((a: SiteSectionModel, b: SiteSectionModel) => {
                return a.order - b.order
              })
              this.map_items = this.map_items.filter((mi) => {
                if (!Number.isNaN(mi.locationDisplay.latitude) && !Number.isNaN(mi.locationDisplay.longitude)) {
                  return mi
                } 
              })

              const flattenedPoints = new Array<LocationDisplay>();
              this.guruApiMapService.getMapItems().forEach((sim) => {
                if (!Number.isNaN(sim.locationDisplay.latitude) && !Number.isNaN(sim.locationDisplay.longitude)) {
                  flattenedPoints.push(sim.locationDisplay)
                }
                // sim.items?.forEach((simItem) => {
                //   if (!Number.isNaN(simItem.locationDisplay.latitude) && !Number.isNaN(simItem.locationDisplay.longitude)) {
                //     flattenedPoints.push(simItem.locationDisplay)
                //   }
                // })
              })
              // console.log(flattenedPoints)
              this.sitePoints = flattenedPoints.filter((ld) => {
                return ld !== undefined
              }) as LocationDisplay[]

              const qp = this.route.snapshot.queryParamMap.get('il')
              if (Number(qp) == d.id) {
                console.log(d)
                setTimeout(() => {
                  this.listingClick(d.id)
                }, 2000)
              }

              // this.sitePoints = (this.guruApiMapService.getMapItems().map((sim) => {
              //   console.log(sim.locationDisplay)
              //   if (!Number.isNaN(sim.locationDisplay.latitude) && !Number.isNaN(sim.locationDisplay.longitude)) {
              //     return sim.locationDisplay
              //   }
              //   // return sim.locationDisplay
              // }).filter((ld) => {
              //   return ld !== undefined
              // }) as LocationDisplay[])
            }
          )
          
        })
      } , error => {
        console.log('error', error);
      })
  }

  getMapUrl(id: number): string{
    if (this.map_items) {
      return this.map_items[id]?.map_image?.original;
    }
    return '';
  }

  goToMap(id: number, flag = ''): void{
    this.router.navigate(['maps/' + id + flag]);
  }
  isCVC(): boolean {
    return environment.apiDomainName == 'cvc.guruwebapp.com' || environment.apiDomainName.includes("cvc")
  }
  getMapById(id: number) {
    return this.map_items.filter((mi) => {
      return mi.id == id
    })[0]
  }
  listingClick(id) {
    if (this.selectedPoint) {
      this.selectedPoint = null
      this.isolatedListing = -1;
      this.clearQueryParam()
      return
    }
    var sectionTarget = this.getMapById(id);
    this.isolatedListing = sectionTarget.order
    this.selectedPoint = sectionTarget.locationDisplay
    const mapItem = this.map_items.find((vx) => {
      return vx.order == this.isolatedListing
    })
    const qp = this.route.snapshot.queryParamMap.get('il')
    if (qp != this.isolatedListing.toString()) {
      this.setQueryParam(id)

    }

    // this.router.navigate(["detail/section/" + mapItem.id])
  }
  pointClick(px) {
    if (px.ld == null) {
      if (this.isolatedListing > -1) {
        this.isolatedListing = -1
        this.selectedPoint = null;
        this.clearQueryParam()
        return
      }
      return
    }
    if (px.isFullscreen) {
      const mapItem = this.map_items.find((vx) => {
        return vx.order == px.ld.id
      })
      // this.goToSection(mapItem.id)
    }
      this.isolatedListing = px.ld.id
      const mapItem = this.map_items.find((vx) => {
        return vx.order == this.isolatedListing
      })
      const qp = this.route.snapshot.queryParamMap.get('il')
      if (qp != mapItem.id.toString()) {
        this.setQueryParam(mapItem.id)
        // this.selectedPoint = mapItem.locationDisplay
      }

      // this.router.navigate(["detail/section/" + mapItem.id])
    
  }
  goToItem(id: number) {
    this.router.navigate(["item/" + id.toString()])
  }
  setQueryParam(id: number) {
    console.log(id)
    this.router.navigate(['/maps'], {
      relativeTo: this.route,
      queryParams: {
        il: id,
      },
      queryParamsHandling: 'merge',
      // skipLocationChange: true,
    })
  }
  clearQueryParam() {
    this.router.navigate(['/maps'])
  }
}
