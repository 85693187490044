import { Component, OnInit, OnDestroy } from '@angular/core';
import {GuruApiMapService} from '../guru-api/guru-api-map.service';
import {SiteSectionModel} from '../guru-api/models/site-section.model';
import { Subject, Subscription } from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';
import { LoadingService } from '../services/loading/loading.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss']
})
export class MapPageComponent implements OnInit, OnDestroy {
   map_item: SiteSectionModel;
   mapServiceSubscription$: Subscription;
   unsubscribe$: Subject<void> = new Subject<void>();
   mapId?: number;
   flag?: string;

  constructor(private guruApiMapService: GuruApiMapService,
              private route: ActivatedRoute,
              private locationStrategy: LocationStrategy,
              private loadingService: LoadingService,
              private guruFirebaseService: GuruFirebaseService) {
    history.pushState(null, null, window.location.href);
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
      const id = params.get('mapId');
      if (id.includes('-')) {
        this.flag = id.split('-')[1];
        this.mapId = Number(id.split('-')[0]);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
      } else {
        this.mapId = Number(id);
      }
      this.loadingService.appState
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(state => {
          if (state){
            this.loadData(this.mapId);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadData(id: number): void {
    const traceMapLoad = this.guruFirebaseService.performance.trace('Map Load');
    traceMapLoad.start();

    this.mapServiceSubscription$ = this.guruApiMapService.loadMapItems(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      (data) => {
        const maps = data.objects.filter(ss => ss?.for_web_app_only);
        this.map_item = maps[id];
        this.guruFirebaseService.logVisitMapPage(this.map_item);
        traceMapLoad.putAttribute('map_name', this.map_item?.name);
        traceMapLoad.stop();
      } , error => {
        console.log('error', error);
        traceMapLoad.stop();
        });
  }

  getMapUrl(): string{
    if (this.map_item) {
      return this.map_item.map_image.original;
    }
    return '';
  }

}
