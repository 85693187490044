import {Injectable, Injector} from '@angular/core';
import {TourModel} from './models/tour.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiEventtimeService extends BaseApiService {
  activities: object = {};
  activitiesList: any[] = [];

  constructor(private injector: Injector) {
    super(injector, 'eventtime/');
  }

  loadActivities(in_progress: string): Observable<ApiListResponseModel<any>> {
    console.log("load activities 2");
    return super.getList<any>({include_in_webapp: 1, in_progress: in_progress});
  }

  getActivities(): any[] {
    return this.activitiesList;
  }
  setActivities(activities: any): void{
    this.activitiesList = activities;
  }
}
