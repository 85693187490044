<div class="container-fluid h-100" *ngIf="tour">
  <div class="tour-header col-12"
       [style.background-image]="'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #F5F5F5 97.92%), url(' + tour.images[0].image.big_retina + ')'">
    <h1 class="align-bottom">{{tour.name}}</h1>
  </div>
  <div class="content-section">
    <!-- <h1 class="col-12">
      {{tour.name}}
    </h1> -->
    <p [innerHTML]="tour.description" class="tour-description col-12">
      
    </p>

    <button class="col-12 btn btn-outline-0 btn start-tour-btn"
            (click)="startTour()"
    >
      {{'tour-intro-page.start-tour' | translate | titlecase}}
    </button>
    <app-tour-stops [text]="'tour-intro-page.tour-stops' | translate" [items]="tour.tour_items" [tourId]="tourId" [tourInfo]="tour.description"></app-tour-stops>
  </div>
  <div class="spacer"></div>
</div>
