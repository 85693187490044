<div class="item-container container-fluid" *ngIf="items" [@inAndOutAnimation]>
  <h3>{{text | uppercase}}</h3>
  <div *ngFor="let item of items; let  i = index"
       class="row item"
       (click)="goToCategory(item.id)"
  >
    <div class="item-img">
      <img class="img-fluid" [src]="item.background_photo.thumb"/>
    </div>
    <span class="item-info">{{item.name}}</span>
  </div>
</div>
