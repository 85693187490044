import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { TourModel } from './guru-api/models/tour.model';
import { GuruApiToursService } from './guru-api/guru-api-tours.service';
import { GuruApiAppService } from './guru-api/guru-api-app.service';
import { AppDetailsModel } from './guru-api/models/app-details.model';
import { GuruApiAboutService } from './guru-api/guru-api-about.service';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { AppAboutModel } from './guru-api/models/app-about.model';
import { MoreMenuStateService } from './services/more-menu-state.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from './services/app-config/app-config.service';
import { GuruApiIteminfoService } from './guru-api/guru-api-iteminfo.service';
import { TourItemModel } from './guru-api/models/tour-item.model';
import { DisplaySettingsService } from './services/display-settings/display-settings.service';
import { environment } from '../environments/environment';
import { WINDOW } from './window';
import { LoadingService } from './services/loading/loading.service';
import { map, takeUntil } from 'rxjs/operators';
import { GuruFirebaseService } from './services/guru-firebase/guru-firebase.service';
import { GuruApiCategoryService } from './guru-api/guru-api-category.service';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-app';
  unsubscribe$: Subject<void> = new Subject<void>();

  // Test code showing how to load data from endpoints
  //
  constructor(private guruApiToursService: GuruApiToursService,
              private guruApiAppService: GuruApiAppService,
              private guruApiAboutService: GuruApiAboutService,
              private guruApiIteminfoService: GuruApiIteminfoService,
              private moreMenuStateService: MoreMenuStateService,
              private translate: TranslateService,
              private cookieService: CookieService,
              private appConfigService: AppConfigService,
              private displaySettingsService: DisplaySettingsService,
              private elementRef: ElementRef,
              private loadingService: LoadingService,
              private guruFirebaseService: GuruFirebaseService,
              private guruApiCategoryService: GuruApiCategoryService,
              private titleService:Title,
              @Inject(WINDOW) private window: Window
  ) {
    if (cookieService.get('language')) {
      translate.setDefaultLang(cookieService.get('language'));
    } else if (appConfigService.language) {
      translate.setDefaultLang(appConfigService.language);
    } else {
      translate.setDefaultLang('en');
      cookieService.set("language", "en");
    }


    //dev handling
    if (window.location.hostname.includes("guruexperience.info")) {
      var splitset = window.location.hostname.split(".")
      // environment.apiDomainName = splitset[0] + ".guruwebapp.com" 
      environment.apiDomainName = "guruwebapp.com"
    } else {
          // App domain is hardcoded in env. Remove for prod build.
      if (window.location.hostname.substr(0, 3) === 'www') {
        environment.apiDomainName = window.location.hostname.substr(4);
      } else {
        environment.apiDomainName = window.location.hostname;
      }
    }



    if (window.location.hostname === 'localhost') {
      // environment.apiDomainName = 'thewynwoodwalls.guruwebapp.com';
      environment.apiDomainName = 'cvc.guruwebapp.com';
      // environment.apiDomainName = 'dali.guruwebapp.com';
    }
    
    this.loadDetails();
  }

  ngOnInit(): void {
    this.displaySettingsService.getConfig().subscribe(config => {
      if (config.primaryColor) {
        this.elementRef.nativeElement.style.setProperty('--primary-color', config.primaryColor);
      }
      if (config.secondaryColor) {
        this.elementRef.nativeElement.style.setProperty('--secondary-color', config.secondaryColor);
      }
    });
    if (environment.apiDomainName == "thewynwoodwalls.guruwebapp.com") {
      this.elementRef.nativeElement.style.setProperty('font-family', 'Mulish')
    }

    this.appConfigService.didLangChange().subscribe(state => {
      this.loadDetails();
    });

    this.loadingService.appState.subscribe(state => {
      if (state && +environment.appId) {
        this.loadData();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getState(): boolean {
    return this.moreMenuStateService.getState();
  }

  loadItemInfo(): void {
    const traceItemsInfoLoad = this.guruFirebaseService.performance.trace('Items Info Initial Load');
    traceItemsInfoLoad.start();
    this.guruApiIteminfoService.loadItemsInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiIteminfoService.setItemsInfo(data.objects);
          console.log('Items loaded!');
          traceItemsInfoLoad.stop();
        },
        error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          traceItemsInfoLoad.stop();
        });
  }

  loadTours(): void {
    console.log("Load tours");
    const traceToursLoad = this.guruFirebaseService.performance.trace('Tours Initial Load');

    traceToursLoad.start();
    this.guruApiToursService.loadTours()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiToursService.setTours(data.objects);
          console.log('Tours loaded!');
          traceToursLoad.stop();
        },
        error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          traceToursLoad.stop();
        });
  }

  loadDetails(): void {
    // const traceDetailsLoad = this.guruFirebaseService.performance.trace('App Details Initial Load');
    this.loadingService.appState.next(false);

    // traceDetailsLoad.start();
    this.guruApiAppService.loadAppDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(map(result => {
        if (result.objects[0].web_app_item_search_fields) {
          const data = result;
          data.objects[0].web_app_item_search_fields = data.objects[0].web_app_item_search_fields.toString()
            .trim()
            .split(',');
          return data.objects[0];
        }
        return result.objects[0];
      }))
      .subscribe(
        data => {
          // if (data.id == 70) {
          //   data.languages = data.languages.filter((val) => {
          //     // console.log(val)
          //     return val == 'en' || val == 'es' || val == 'vi' || val == 'zh' || val == 'fr' || val == 'ja' || val == 'am' || val == 'ko' || val == 'pt' || val == 'it' || val == 'ru' || val == 'ar'   || val == 'hi'         })
          // }
          // this.title = data
          const appDetails = data;
          this.guruApiAppService.setAppDetails(appDetails);
          if (appDetails) {
            this.displaySettingsService.setConfig({
              primaryColor: appDetails.web_app_primary_color,
              museumLogo: appDetails.web_app_logo_image.original,
              splashLogo: appDetails.web_app_splashscreen_image.original
            });

            if (appDetails.web_app_firebase_config) {
              const firebaseConfig = JSON.parse(appDetails.web_app_firebase_config.replace(/'/g, '"'));
              this.guruFirebaseService.initializeFirebase(firebaseConfig);
            }

            environment.appId = appDetails.id;
          } else {
            throw new Error('Failed to initialize App');
          }
          this.loadingService.appState.next(true);
          // traceDetailsLoad.stop();
        }, error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          // traceDetailsLoad.stop();
        }
      );
  }

  loadAbout(): void {
    const traceAboutLoad = this.guruFirebaseService.performance.trace('About Details Initial Load');

    traceAboutLoad.start();
    this.guruApiAboutService.loadAppAbout()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiAboutService.setAppAbout(data);
          // this.title = data.museum_name
          this.titleService.setTitle(data.museum_name)
          console.log('About info loaded!');
          traceAboutLoad.stop();
        }, error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
          traceAboutLoad.stop();
        }
      );
  }

  loadCategory(): void{
    const traceCategoriesLoad = this.guruFirebaseService.performance.trace('Categories Initial Load');

    traceCategoriesLoad.start();
    this.guruApiCategoryService.loadCategories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( data => {
        this.guruApiCategoryService.setCategories(data.objects);
        console.log('Categories loaded!');
        traceCategoriesLoad.stop();
      }, error => {
        console.log('error', error);
        traceCategoriesLoad.stop();
      });
  }

  loadData(): void {
    this.loadAbout();
    this.loadItemInfo();
    this.loadTours();
    this.loadCategory();
  }
}
