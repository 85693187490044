import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {GuruApiMapService} from '../guru-api/guru-api-map.service';
import {SiteSectionModel} from '../guru-api/models/site-section.model';
import {Router} from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { TourModel } from '../guru-api/models/tour.model';
import {GuruApiEventtimeService} from "../guru-api/guru-api-eventtime.service";

@Component({
  selector: 'app-today-activities',
  templateUrl: './today-activities.component.html',
  styleUrls: ['./today-activities.component.scss']
})
export class TodayActivitiesComponent implements OnInit, OnDestroy {
  activities: any[];
  unsubscribe$: Subject<void> = new Subject<void>();
  constructor(private guruApiEventtimeService: GuruApiEventtimeService,
              private router: Router,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.loadingService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.show) {
          this.loadData();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  convertTZ(date, tzString): Date {
    return new Date((typeof date === "string" || typeof date === "number" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }
  loadData(): void {
    // const activities = this.guruApiEventtimeService.getActivities();
    // if (activities && activities.length > 0) {
    //   this.filterActivities(activities);
    //   return;
    // }
    let today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const in_progress = y + '-' + m + '-' + d;
    this.guruApiEventtimeService.loadActivities(in_progress)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          data.objects.forEach( (object: any) =>{
            object.start_time *= 1000;
            object.end_time *= 1000;
            object.start_time = this.convertTZ(object.start_time, 'US/Central')
            object.end_time = this.convertTZ(object.end_time, 'US/Central');
          })
          this.filterActivities(data.objects);
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        },
        error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
        });
  }
  filterActivities(activities: any[]): void {
    const d = new Date().getDate();
    const m = new Date().getMonth();
    const y = new Date().getFullYear();
    this.activities = activities.filter( (activity: any) => {
      const date = new Date(activity.start_time);
      const _d = date.getDate();
      const _m = date.getMonth();
      const _y = date.getFullYear();
      return d === _d && m === _m && y === _y;
    });
    this.guruApiEventtimeService.setActivities(this.activities);
  }
  getActivityImgUrl(id: number): string {
    return this.activities.filter(a => a.event.id === id)[0].event.default_image.full;
  }
  goToEvent(eventId: number): void {
    this.router.navigate([ 'event/' + eventId ]);
  }
}
