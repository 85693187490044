import {Component, OnInit} from '@angular/core';
import {MoreMenuStateService} from '../services/more-menu-state.service';
import {Router} from "@angular/router";
import {GuruApiAppService} from "../guru-api/guru-api-app.service";
import {Subject} from "rxjs/index";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {
  active = 'home';
  appDetails: any;
  showActivities: boolean = false;
  onSelect(button: string): void {
    let appDetails = this.guruApiAppService.getAppDetails();
    this.active = button;
    if(this.active == 'activities'){
      if(appDetails.web_app_calendar_icon_to_full_month){
        this.router.navigate(['calendar']);
      }
      else{
        this.router.navigate(['today-activities']);
      }
      this.moreMenuState.setState(false);
    }
    else{
      if (this.active !== 'more') {
        this.moreMenuState.setState(false);
        return;
      }
      this.moreMenuState.toggleState();
    }
  }

  constructor(private moreMenuState: MoreMenuStateService,
              private router: Router,
              private guruApiAppService: GuruApiAppService ) {
    this.guruApiAppService.loadAppDetails().subscribe( (appDetails) => {
      this.showActivities = appDetails.objects[0]['web_app_show_activities'] ? appDetails.objects[0]['web_app_show_activities'] : false;
    })
    const path = window.location.pathname && window.location.pathname.length > 0 ? window.location.pathname.substring(1) : '';
    switch (path){
      case 'maps':{
        this.active = 'map';
        break;
      }
      case 'map': {
        this.active = 'map';
        break;
      }
      case 'today-activities': {
        this.active = 'activities';
        break;
      }
      case 'calendar': {
        this.active = 'activities';
        break;
      }
      case 'search': {
        this.active = 'keypad'
        break;
      }
      default: {
        this.active = 'home';
      }
    }
  }

  ngOnInit(): void {
  }

}
