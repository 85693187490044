<div class="item-container container-fluid" *ngIf="items">
  <h3>{{text | uppercase}}</h3>
  <div *ngFor="let item of getItems()|slice:0:range; let  i = index"
       class="row item"
       (click)="redirectTo(item.id)"
  >
    <div class="item-img">
      <img class="img-fluid" [src]="item.image.original"/>
      <div class="item-id rounded-circle" *ngIf="item?.map_item_id">
        <div>
          {{item.map_item_id}}
        </div>
      </div>
    </div>
    <span class="item-info">{{item.name}}</span>
  </div>
  <div *ngIf="canLoad()" class="load-more" (click)="loadMore()">{{'item-list.load-more' | translate | uppercase}}</div>
  <div class="spacer"></div>
</div>
