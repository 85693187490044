<div class="bottom-menu">
  <button class="home-button btn-default"
          (click)="onSelect('home')"
          [class.btn-active]="active==='home'"
          routerLink="home"
  >
    <i></i>
    <span>{{ 'bottom-menu.home' | translate }}</span>
  </button>
  <button class="keypad-button btn-default"
          (click)="onSelect('keypad')"
          [class.btn-active]="active==='keypad'"
          routerLink="search"
  >
    <i></i>
    <span>{{ 'bottom-menu.search' | translate }}</span>
  </button>
  <button class="activities-button btn-default"
          (click)="onSelect('activities')"
          [class.btn-active]="active==='activities'"
          *ngIf="showActivities"
  >
    <i></i>
    <span>{{ 'bottom-menu.activities' | translate }}</span>
  </button>
  <button class="map-button btn-default"
          (click)="onSelect('map')"
          [class.btn-active]="active==='map'"
          routerLink="maps"
  >
    <i></i>
    <span>{{ 'bottom-menu.maps' | translate }}</span>
  </button>


  <button class="more-button btn-default"
          (click)="onSelect('more')"
          [class.btn-active]="active==='more'"
  >
    <i></i>
    <span>{{ 'bottom-menu.more' | translate }}</span>
  </button>
</div>
