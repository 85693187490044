import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SplashPageComponent} from './splash-page/splash-page.component';
import {LanguagePageComponent} from './language-page/language-page.component';
import {HomePageComponent} from './home-page/home-page.component';
import {AboutUsPageComponent} from './about-us-page/about-us-page.component';
import {SettingsPageComponent} from './settings-page/settings-page.component';
import {MoreMenuComponent} from './more-menu/more-menu.component';
import {FormsModule} from '@angular/forms';
import {SearchTourPageComponent} from './search-tour-page/search-tour-page.component';
import {TourIntroPageComponent} from './tour-intro-page/tour-intro-page.component';
import { TourStopPageComponent } from './tour-stop-page/tour-stop-page.component';
import {MapListComponent} from './map-list/map-list.component';
import {MapPageComponent} from './map-page/map-page.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import {TodayActivitiesComponent} from "./today-activities/today-activities.component";
import {CalendarComponent} from "./calendar/calendar.component";
import {EventDetailsComponent} from "./event-details/event-details.component";
import { DetailPageComponent } from './detail-page/detail-page.component';

const routes: Routes = [
  {path: '', redirectTo: 'language', pathMatch: 'full'},
  {path: 'language', component: LanguagePageComponent},
  {path: 'home', component: HomePageComponent},
  {path: 'about-us', component: AboutUsPageComponent},
  {path: 'settings', component: SettingsPageComponent},
  {path: 'search', component: SearchTourPageComponent},
  {path: 'tour/:id', component: TourIntroPageComponent},
  {path: 'maps', component: MapListComponent},
  // {path: 'livemap', component:}
  {path: 'maps/:mapId', component: MapPageComponent},
  {path: 'more', component: MoreMenuComponent},
  {path: 'more/about-us', component: AboutUsPageComponent},
  {path: 'more/settings', component: SettingsPageComponent},
  {path: 'tourStop/:tourId/:tourStopIndex', component: TourStopPageComponent},
  {path: 'tourStop/:tourId/:tourStopIndex/:itemId', component: TourStopPageComponent},
  {path: 'item/:itemId', component: ItemDetailsComponent},
  {path: 'category/:id', component: CategoryDetailsComponent},
  {path: 'today-activities', component: TodayActivitiesComponent},
  {path: 'calendar', component: CalendarComponent},
  {path: 'event/:id', component: EventDetailsComponent},
  {path: 'detail/:kind/:id', component: DetailPageComponent},
];

@NgModule({
  imports: [FormsModule,
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
