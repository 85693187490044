import { Component, OnInit, OnDestroy } from '@angular/core';
import {GuruApiAboutService} from '../guru-api/guru-api-about.service';
import { Subject, Subscription } from 'rxjs';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { AppAboutModel } from '../guru-api/models/app-about.model';
import {CookieService} from "ngx-cookie-service";
import {GuruApiAppService} from "../guru-api/guru-api-app.service";

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  appAbout: AppAboutModel;
  rtl: boolean = false;
  constructor(private guruApiAboutService: GuruApiAboutService,
              private loadingService: LoadingService,
              private cookieService: CookieService,
              private guruApiAppService: GuruApiAppService) {
  }

  ngOnInit(): void {
    const langDetails = this.guruApiAppService.getLangName(this.cookieService.get('language'));
    this.rtl = !!langDetails['rtl'];
    this.loadingService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( state => {
      if(!state.show){
        this.loadAbout();
      }
    })
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadAbout(): void {
    const about = this.guruApiAboutService.getAppAbout();
    if(about){
      this.appAbout = about;
      return;
    }

    this.guruApiAboutService.loadAppAbout()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      data => {
        this.appAbout = data;
        this.guruApiAboutService.setAppAbout(data);
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
      }
    );
  }

}
