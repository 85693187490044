import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppDetailsModel } from '../guru-api/models/app-details.model';
import { Subject, Subscription } from 'rxjs';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';

@Component({
  selector: 'app-search-tour-page',
  templateUrl: './search-tour-page.component.html',
  styleUrls: ['./search-tour-page.component.scss']
})
export class SearchTourPageComponent implements OnInit, OnDestroy {
  query?: string;
  typing?: boolean;
  appDetails: AppDetailsModel;
  unsubscribe$: Subject<void> = new Subject<void>();
  searchType: string;
  translationKey = 'search-tour-page.search-default';

  constructor(private guruApiAppService: GuruApiAppService,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.loadingService.loaderState.subscribe( state => {
      if (!state.show) {
        this.loadDetails();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  searchTours(term: string): void {
    this.query = term;
    this.typing = term.length > 0;
  }

  getSearchType(): string{
    let type: string;
    ['text', 'keypad'].forEach(t => {
      if(this.appDetails?.web_app_search_type === t){
        type = t === 'keypad' ? 'number' : t;
      }
    });
    return type || 'text';
  }

  getTranslationKey(): void{
    if (this.searchType){
      this.translationKey = this.searchType === 'number' ? 'search-tour-page.search-keypad-placeholder' : 'search-tour-page.search-placeholder';
    }
  }

  loadDetails(): void {
    const appDetails = this.guruApiAppService.getAppDetails();
    if(appDetails){
      this.appDetails = appDetails;
      this.searchType = this.getSearchType();
      this.getTranslationKey();
      return;
    }

    this.guruApiAppService.loadAppDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.appDetails = data.objects[0];
          this.searchType = this.getSearchType();
          this.getTranslationKey();
        }, error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
        }
      );
  }
}
