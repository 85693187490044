<app-loading-page></app-loading-page>
<div *ngIf="!isLoading">
<app-splash-page></app-splash-page>
<div class="language-container">
  <app-logo></app-logo>
  <div class="message-box">
    <h2>{{'language-page.hello' | translate | titlecase}}!</h2>
    <h3>{{'language-page.choose-your-language' | translate}}</h3>
  </div>

  <div class="language-list">
    <ul>
      <li class="language"
          *ngFor="let language of langCodes"
          (click)="onSelect(language)"
      >
        <span [ngClass]="selected===language ? 'selected-lang' : 'not-selected'">{{this.getLangName(language).nativeName | uppercase}}</span>
        <div *ngIf="selected===language" class="ok-icon"></div>
      </li>
    </ul>
  </div>
  <button class="start-button" routerLink="/home">{{'language-page.start' | translate | titlecase}}</button>
</div>
</div>
