import { Component, OnInit, OnDestroy } from '@angular/core';
import {of, Subject} from 'rxjs';
import {GuruApiMapService} from '../guru-api/guru-api-map.service';
import {SiteSectionModel} from '../guru-api/models/site-section.model';
import {ActivatedRoute, Router} from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { TourModel } from '../guru-api/models/tour.model';
import {GuruApiEventtimeService} from "../guru-api/guru-api-eventtime.service";
import {NgbCalendar, NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {GuruApiTourTimesService} from "../guru-api/guru-api-tour-times.service";
import {CategoryModel} from "../guru-api/models/category.model";
import {mergeMap} from "rxjs/internal/operators";
import {GuruApiEventService} from "../guru-api/guru-api-event.service";
import {GuruApiAppService} from "../guru-api/guru-api-app.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  eventId: number;
  event: any;
  rtl: boolean = false;

  constructor(private guruApiTourTimesService: GuruApiTourTimesService,
              private router: Router,
              private route: ActivatedRoute,
              private loadingService: LoadingService,
              private guruApiEventService: GuruApiEventService,
              private cookieService: CookieService,
              private guruApiAppService: GuruApiAppService) {

  }

  ngOnInit(): void {
    const langDetails = this.guruApiAppService.getLangName(this.cookieService.get('language'));
    this.rtl = !!langDetails['rtl'];
    this.route.paramMap
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(mergeMap(paramMap => {
        this.eventId = Number(paramMap.get('id'));
        const _event = this.guruApiEventService.getEvent(this.eventId);
        if(_event){
          this.event = _event;
          return of(<any>{show: false});
        }
        return this.loadingService.loaderState
      }))
      .subscribe( state => {
        if(!state.show){
          // this.traceTimeSpent = this.guruFirebaseService.performance.trace('Tour Intro Time Spent')
          // this.traceTimeSpent.start();
          if (!this.event) {
            this.loadEvent(this.eventId);
          }
        }
      })
  }
  loadEvent(eventId: number): void {
    if(!this.event){
      const _event = this.guruApiEventService.getEvent(this.eventId);

      if(_event){
        this.event = _event;
        return;
      }
    }

    this.guruApiEventService.loadEvent(eventId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: any) => {
          this.event = data;
        }, error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
        });
  }
  ngOnDestroy(): void {
  }
}
