import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {TourModel} from '../guru-api/models/tour.model';
import { Subject, Subscription } from 'rxjs';
import {GuruApiToursService} from '../guru-api/guru-api-tours.service';
import {Router} from '@angular/router';
import {query} from '@angular/animations';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';


@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['./tour-list.component.scss']
})
export class TourListComponent implements OnInit, OnDestroy {
  @Input() text = 'tours';
  tours: TourModel[];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private guruApiToursService: GuruApiToursService,
              private guruApiAppService: GuruApiAppService,
              private router: Router,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.loadingService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.show) {
          this.loadTours();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadTours(): void {
    const tours = this.guruApiToursService.getTours();
    if (tours) {
      this.filterTours(tours);
      return;
    }

    this.guruApiToursService.loadTours()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.filterTours(data.objects);
        },
        error => {
          if (error.error.detail === 'Invalid page.') {
          }
          console.log('error', error);
        });
  }

  getTourImgUrl(id: number): string {
    return this.tours.filter(t => t.id === id)[0].images[0].image.original;
  }

  getTourInfo(id: number): string {
    return this.tours.filter(t => t.id === id)[0].name;
  }

  goToTour(toruId: number): void {
    this.router.navigate(['tour/' + toruId]);
  }

  filterTours(tours: TourModel[]): void {
    if (this.guruApiAppService.getAppDetails().web_app_self_guided_only) {
      this.tours = tours.filter(t => t?.self_guides);
      this.guruApiToursService.setTours(this.tours);
      return;
    }
    this.tours = tours;
    this.guruApiToursService.setTours(this.tours);
  }
}
