import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {GuruApiMapService} from '../guru-api/guru-api-map.service';
import {SiteSectionModel} from '../guru-api/models/site-section.model';
import {Router} from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { TourModel } from '../guru-api/models/tour.model';
import {GuruApiEventtimeService} from "../guru-api/guru-api-eventtime.service";
import {NgbCalendar, NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {GuruApiTourTimesService} from "../guru-api/guru-api-tour-times.service";
import {CategoryModel} from "../guru-api/models/category.model";
import {I18n} from "./datepicker-i18n";
import {AppConfigService} from "../services/app-config/app-config.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  tourTimes: any[];
  unsubscribe$: Subject<void> = new Subject<void>();
  selectedMonth: number = new Date().getMonth() + 1;
  selectedYear: number = new Date().getFullYear();
  selectedDay: NgbDateStruct = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  };
  showCalendar: boolean = false;
  constructor(private guruApiTourTimesService: GuruApiTourTimesService,
              private router: Router,
              private loadingService: LoadingService,
              private calendar: NgbCalendar,
              private guruApiEventtimeService: GuruApiEventtimeService,
              private i18n: I18n,
              private cookieService: CookieService) {
    let currentLanguage = this.cookieService.get('language');
    if(!currentLanguage){
      currentLanguage = 'en';
    }
    this.showCalendar = false;
    this.i18n.language = currentLanguage;
    this.showCalendar = true;
    this.loadingService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.show) {
          this.loadData(this.selectedMonth, this.selectedYear);
        }
      });
  }

  ngOnInit(): void {

  }
  filterItems(){
    if(!this.tourTimes || (this.tourTimes && this.tourTimes.length <= 0)){
      return [];
    }
    else{
      return this.tourTimes.filter( (item: any) => {
        const tourTimeDate = new Date(item.type == 'tour' ? item.start_date : item.start_time);
        const _d = tourTimeDate.getDate();
        const _m = tourTimeDate.getMonth() + 1;
        return this.selectedDay.day === _d && this.selectedDay.month === _m;
      });
    }
  }
  withEvents(date: NgbDate){
    let withEvents = false;
    if(this.tourTimes && date){
      this.tourTimes.forEach( (item: any) => {
        const itemDate = new Date(item.type == 'tour' ? item.start_date : item.start_time);
        const _d = itemDate.getDate();
        const _m = itemDate.getMonth() + 1;
        if(date.day === _d && date.month === _m){
          withEvents = true;
        }
      });
    }
    return withEvents;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  convertTZ(date, tzString): Date {
    return new Date((typeof date === "string" || typeof date === "number" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }
  isDisabled = (date: NgbDate, current: { month: number; year: number }) => date.month !== current.month;
  getTourImgUrl(id: number): string {
    return this.tourTimes.filter(t => t.type == 'tour' && t.tour.id === id)[0].tour.images.image.full;
  }

  getTourInfo(id: number): string {
    return this.tourTimes.filter(t => t.type == 'tour' && t.tour.id === id)[0].tour.name;
  }
  loadData(month: number, year: number): void {
    let in_progress = year + '-' + month + '-1';
    this.guruApiTourTimesService.loadTourTimes(month, year)
      .subscribe(data => {
        data.objects.forEach( (object: any) =>{
          object.type = 'tour';
          object.start_date *= 1000;
          object.end_date *= 1000;
          object.start_date = this.convertTZ(object.start_date, 'US/Central')
          object.end_date = this.convertTZ(object.end_date, 'US/Central');
        });
         let items = data.objects;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.guruApiEventtimeService.loadActivities(in_progress)
          .subscribe(
            data2 => {
              data2.objects.forEach( (object: any) =>{
                object.type = 'event';
                object.start_time *= 1000;
                object.end_time *= 1000;
                object.start_time = this.convertTZ(object.start_time, 'US/Central')
                object.end_time = this.convertTZ(object.end_time, 'US/Central');
                items.push(object);
                items.sort( (a: any, b: any) => {
                  let v1 = new Date(a.type == 'tour'? a.start_date : a.start_time).getTime();
                  let v2 = new Date(b.type == 'tour'? b.start_date : b.start_time).getTime();
                  return v1 - v2;
                })
                this.tourTimes = items;
                this.unsubscribe$.next();
                this.unsubscribe$.complete();
              });
            },
            error => {
              if (error.error.detail === 'Invalid page.') {
              }
              console.log('error', error);
            });
      }, error => {
        console.log('error', error);
      });
  }
  getEventImgUrl(id: number): string {
    return this.tourTimes.filter(a => a.type == 'event' && a.event.id === id)[0].event.default_image.full;
  }
  goToEvent(eventId: number): void {
    this.router.navigate([ 'event/' + eventId ]);
  }
  goToTour(tourId: number): void {
    this.router.navigate([ 'tour/' + tourId ]);
  }
  changeMonth($event?: any){
    if($event && $event.next && $event.current){
      this.selectedDay = null;
      this.selectedMonth = $event.next.month;
      this.selectedYear = $event.next.year;
      this.loadData(this.selectedMonth, this.selectedYear);
    }
  }
}
