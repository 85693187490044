import {Component, Input, OnInit} from '@angular/core';
import {TourModel} from '../guru-api/models/tour.model';
import {Router} from '@angular/router';
import { CategoryItemModel } from '../guru-api/models/category-item.model';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { TourStopModel } from '../guru-api/models/tour-stop.model';

@Component({
  selector: 'app-tour-stops',
  templateUrl: './tour-stops.component.html',
  styleUrls: ['./tour-stops.component.scss']
})
export class TourStopsComponent implements OnInit {
  @Input() text: string;
  @Input() items: TourItemModel[] | CategoryItemModel[];
  @Input() tourInfo: string;
  @Input() tourId?: number;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  redirectTo(id: number): void{
    if(this.items[id].hasOwnProperty('item')){
      this.router.navigate(['tourStop/'+ this.tourId + '/' + id]);
      return;
    }
    this.router.navigate(['item/'+ this.items[id].id]);
  }
}
