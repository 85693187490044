import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { Router } from '@angular/router';
import { LanguagePageComponent } from './language-page/language-page.component';
import { SplashPageComponent } from './splash-page/splash-page.component';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import {NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomePageComponent } from './home-page/home-page.component';
import { FormsModule } from '@angular/forms';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { TourListComponent} from './tour-list/tour-list.component';
import { SearchTourPageComponent} from './search-tour-page/search-tour-page.component';
import { TourIntroPageComponent } from './tour-intro-page/tour-intro-page.component';
import { TourStopsComponent } from './tour-stops/tour-stops.component';
import { TourStopPageComponent } from './tour-stop-page/tour-stop-page.component';
import { MatSliderModule } from '@angular/material/slider';
import { LanguageInterceptorService } from './services/language/language-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MapListComponent } from './map-list/map-list.component';
import { MapPageComponent } from './map-page/map-page.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { ItemListComponent } from './item-list/item-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { LogoComponent } from './logo/logo.component';
import { WINDOW_PROVIDERS } from './window';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '../environments/environment';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryChildrenListComponent } from './category-children-list/category-children-list.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { GalleryItemModalComponent } from './widgets/gallery-item-modal/gallery-item-modal.component';
import { SafePipe } from './pipes/safe/safe.pipe';
import {TodayActivitiesComponent} from "./today-activities/today-activities.component";
import {CalendarComponent} from "./calendar/calendar.component";
import {EventDetailsComponent} from "./event-details/event-details.component";
import {CustomDatepickerI18n, I18n} from "./calendar/datepicker-i18n";
import { ArcmapComponent } from './arcmap/arcmap.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { SectionDetailDisplayComponent } from './detail-displays/section-detail-display/section-detail-display.component';

if(environment.production) {
  Sentry.init({
    dsn: 'https://b6e36cb1fc6f49239c53ba743fae1fd6@o494649.ingest.sentry.io/5843023',
    environment: 'production',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.1,
  });
} else {
  Sentry.init({
    dsn: 'https://b6e36cb1fc6f49239c53ba743fae1fd6@o494649.ingest.sentry.io/5843023',
    environment: 'development',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LanguagePageComponent,
    SettingsPageComponent,
    SplashPageComponent,
    BottomMenuComponent,
    HomePageComponent,
    MoreMenuComponent,
    AboutUsPageComponent,
    TourListComponent,
    SearchTourPageComponent,
    TourIntroPageComponent,
    TourStopsComponent,
    TourStopPageComponent,
    MapListComponent,
    MapPageComponent,
    ItemDetailsComponent,
    ItemListComponent,
    LoadingPageComponent,
    LogoComponent,
    CategoryListComponent,
    CategoryChildrenListComponent,
    CategoryDetailsComponent,
    ImageGalleryComponent,
    GalleryItemModalComponent,
    SafePipe,
    TodayActivitiesComponent,
    CalendarComponent,
    EventDetailsComponent,
    ArcmapComponent,
    DetailPageComponent,
    SectionDetailDisplayComponent,
  ],
    imports: [
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        MatSliderModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        }),
        MatProgressSpinnerModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true
    },
    CookieService,
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
