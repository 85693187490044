import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription} from 'rxjs';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import {AppConfigService} from '../services/app-config/app-config.service';
import {CookieService} from 'ngx-cookie-service';
import { AppDetailsModel } from '../guru-api/models/app-details.model';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})

export class SettingsPageComponent implements OnInit, OnDestroy {
  langCodes: string[];
  appDetailsSubscription$: Subscription;
  currentLanguage: string;

  constructor(private guruApiAppService: GuruApiAppService,
              private appConfigService: AppConfigService,
              private cookieService: CookieService) { }

  ngOnInit(): void {
    this.langCodes = this.appConfigService.getLanguages();
    this.currentLanguage = this.appConfigService.language;
    if(!this.langCodes || !this.currentLanguage) {
      this.langCodes = ['en'];
      this.loadDetails();
    }
  }

  ngOnDestroy(): void {
    if(this.appDetailsSubscription$){
      this.appDetailsSubscription$.unsubscribe();
    }
  }

  getLangName(code: string): string{
    return this.guruApiAppService.getLangName(code);
  }

  onSelectChange(code: string): void {
    this.currentLanguage = code;
    this.appConfigService.language = this.currentLanguage;
    this.cookieService.set('language', this.currentLanguage);
  }

  initLanguage(appDetails: AppDetailsModel): void{
    this.langCodes = [...this.langCodes, ...appDetails.languages];
    this.langCodes = [...new Set(this.langCodes)];
    this.appConfigService.setLanguages(this.langCodes);
    if(this.cookieService.check('language')) {
      this.currentLanguage = this.cookieService.get('language');
    } else {
      this.currentLanguage = this.langCodes[0];
      this.cookieService.set('language', this.currentLanguage);
    }
  }

  loadDetails(): void {
    const appDetails = this.guruApiAppService.getAppDetails();
    if(appDetails){
      this.initLanguage(appDetails);
      return;
    }
    this.appDetailsSubscription$ = this.guruApiAppService.loadAppDetails().subscribe(
      data => {
        // if (data.objects[0].id == 70) {

        //   data.objects[0].languages = data.objects[0].languages.filter((val) => {
        //     // console.log(val)
        //     return val == 'en' || val == 'es' || val == 'vi' || val == 'ar' || val == 'fr' || val == 'ja' || val == 'am' || val == 'ko' || val == 'pt' || val == 'it' || val == 'ru' || val == 'zh' || val == 'ar' || val == 'hi'
        //   })
        // }
        this.initLanguage(data.objects[0]);
      }, error => {
        if (error.error.detail === 'Invalid page.') {
        }
        console.log('error', error);
      }
    );
  }

}
