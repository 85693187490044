import {Injectable, Injector} from '@angular/core';
import {TourModel} from './models/tour.model';
import {BaseApiService} from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import {MetaModel} from './models/meta.model';
import {ApiListResponseModel} from './models/api-list-response.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiToursService extends BaseApiService {
  tours: object = {};
  toursList: TourModel[] = [];

  constructor(private injector: Injector) {
    super(injector, 'tour/');
  }

  loadTours(): Observable<ApiListResponseModel<TourModel>> {
    console.log("load turs 2");
    return super.getList<TourModel>({include_in_webapp: 1});
  }

  loadTour(id): Observable<TourModel> {
    const response = super.getDetail<TourModel>(id);
    response.subscribe(
      (data: TourModel) => {
        this.tours[id] = data;
      }, error => {
        console.log('Error loading data: ', error);
      });
    return response;
  }

  getTours(): TourModel[] {
    return this.toursList;
  }

  setTours(tours: TourModel[]): void{
    this.toursList = tours;
  }

  getTour(tourId): TourModel {
    return this.tours[tourId];
  }

  resetTours(): void{
    this.toursList = []
    this.tours = {};
  }
}
