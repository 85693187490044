<!-- <p>arcmap works!</p> -->
<div id="map-container" class="container-fluid" #mapContainer [ngClass]="{'fullscreen': fullscreenMode}">
    <div id="first-use" (click)="setFirstClick()" *ngIf="firstClick < 1 && firstClick > -1">
        <div class="text">
            <p>Chart your course with our interactive map.</p>
            <br>
            <p>Tap to start.</p>
        </div>
    </div>
    <div id="mapbox">
      
    </div>
</div>