import { Injectable, Injector } from '@angular/core';
import { TourModel } from './models/tour.model';
import { BaseApiService } from './base/base-api.service';
import { Observable, Subject } from 'rxjs';
import { MetaModel } from './models/meta.model';
import { ApiListResponseModel } from './models/api-list-response.model';
import { TourItemModel } from './models/tour-item.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiIteminfoService extends BaseApiService {
  itemsInfo: TourItemModel[] = [];

  constructor(private injector: Injector) {
    super(injector, 'iteminfo/');
  }

  loadItemsInfo(): Observable<ApiListResponseModel<TourItemModel>> {
    return super.getList<TourItemModel>();
  }

  setItemsInfo(items: TourItemModel[]): void{
    this.itemsInfo = items;
  }

  getItemInfo(id: number): TourItemModel {
    return this.itemsInfo.filter(i => Number(i.map_item_id) === id)[0];
  }

  getItemsInfo(): TourItemModel[] {
    return this.itemsInfo;
  }
}
