import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisplaySettingsService } from '../services/display-settings/display-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit, OnDestroy {
  show = true;
  opacity: number;
  splash_screen: string;
  displaySettingsSubscription$: Subscription;

  constructor(private displaySettingsService: DisplaySettingsService) {
  }

  ngOnInit(): void {
    this.displaySettingsSubscription$ = this.displaySettingsService.getConfig().subscribe( config => {
      if(config.splashLogo && config.splashLogo.length) {
        this.splash_screen = config.splashLogo;
      }
    });

    setTimeout(() => {
      setTimeout(() => {
        this.show = false;
      }, 1500);
      this.opacity = 0;
    }, 3000);
  }

  ngOnDestroy(): void{
    this.displaySettingsSubscription$ && this.displaySettingsSubscription$.unsubscribe();
  }
}
