<div class="stops-container container-fluid">
  <h3>{{text | uppercase}}</h3>
  <div *ngFor="let item of items; let i = index" class="row stop-item" (click)="redirectTo(i)">
    <div class="stop-img">
      <img class="img-fluid" [src]="item.image?.original || item?.item?.image?.original"/>
      <div class="stop-id rounded-circle" *ngIf="item?.item?.map_item_id">
        <div>
          {{item?.item?.map_item_id}}
        </div>
      </div>
    </div>
    <span class="stop-info">{{item.name || item?.item?.name}}</span>
  </div>
  <div class="spacer"></div>
</div>
