import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GuruApiIteminfoService } from '../guru-api/guru-api-iteminfo.service';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { hasProperties } from 'codelyzer/util/astQuery';


@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnDestroy {
  @Input() text?: string;
  @Input() query?: string;
  @Input() search_fields?: string[];

  range: number;
  items: TourItemModel[] = [];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private guruApiItemInfoService: GuruApiIteminfoService,
              private loadingService: LoadingService,
              private router: Router) {
    this.range = 50;
  }

  ngOnInit(): void {
    this.loadingService.loaderState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state.show) {
          this.loadItemInfo();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadItemInfo(): void {
    const items = this.guruApiItemInfoService.getItemsInfo();
    if (items && items?.length) {
      this.items = items;
      this.sortItems();
      return;
    }
    this.guruApiItemInfoService.loadItemsInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiItemInfoService.setItemsInfo(data.objects);
          this.items = data.objects;
          this.sortItems();
          console.log('Items loaded!');
        },
        error => {
          console.log('error', error);
        });
  }

  sortItems(): void {
    if (this.items?.length) {
      this.items = this.items.sort((a, b) => Number(a?.map_item_id) - Number(b?.map_item_id));
    }
  }

  getItems(): TourItemModel[] {
    if (!this.query) {
      return this.items;
    }

    return this.items.filter(t => {
      if (!this.search_fields || !this.search_fields?.length) {
        return t.name.toLowerCase().includes(this.query.toLowerCase());
      }

      return this.search_fields.some(field => {
        if (t.hasOwnProperty(field)) {
          return t[field]?.toString().toLowerCase().includes(this.query.toLowerCase());
        }
      });
    });
  }

  loadMore(): void {
    this.range += 50;
  }

  redirectTo(id: number): void {
    this.router.navigate(['item/' + id]);
  }

  canLoad(): boolean {
    const length = this.getItems().length;
    if (length) {
      return length > this.range;
    }
    return false;
  }
}

