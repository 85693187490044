<div class="category-container container-fluid" *ngIf="categories?.length">
  <h3>{{text | uppercase}}</h3>
  <div>
    <div *ngFor="let category of categories; let i = index" class="row category-item" (click)="goToCategory(category.id, i)">
      <img class="img-fluid w-100" src="{{category.background_photo.original}}" alt="{{category.name}}"/>
      <span class="category-info w-100">{{category.name | titlecase}}</span>
      <app-category-children-list *ngIf="showSubCategories[0] === i && showSubCategories[1]" [items]="subCategories.length ? subCategories : null" [text]="'subcategories'"></app-category-children-list>
    </div>
  </div>

  <div class="spacer" style="min-height: 15vh"></div>
</div>
