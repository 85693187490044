<div class="container-fluid h-100" *ngIf="event">
  <div class="event-header col-12"
       [style.background-image]="'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #F5F5F5 97.92%), url(' + event.default_image.full + ')'">
  </div>
  <div class="col-12">
    <h1>{{event.name}}</h1>
  </div>
  <div class="content-section"  [innerHTML]="event.description" [dir]="rtl? 'rtl' : 'ltr'" [ngClass]="rtl? 'text-right' : ''">
  </div>
  <div class="spacer"></div>
</div>
