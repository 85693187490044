<div class="about-container text-center">
  <app-logo></app-logo>
  <div class="col-12 info text-left">
    <h2>{{'about-us-page.about-us' | translate | titlecase}}</h2>
    <p *ngIf="appAbout && appAbout.cleaned_overview" [dir]="rtl? 'rtl' : 'ltr'" [ngClass]="rtl? 'text-right' : ''">
      {{appAbout.cleaned_overview}}
    </p>
  </div>
  <div class="spacer" style="min-height: 20vh"></div>
</div>
