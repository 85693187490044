import { Injector, Injectable } from '@angular/core';
import { BaseApiService } from './base/base-api.service';
import { Observable } from 'rxjs';
import { ApiListResponseModel } from './models/api-list-response.model';
import { TourModel } from './models/tour.model';
import { CategoryModel } from './models/category.model';

@Injectable({
  providedIn: 'root'
})
export class GuruApiCategoryService extends BaseApiService{
  categories: CategoryModel[];

  constructor(private injector: Injector) {
    super(injector, 'category/', '/children')
  }

  loadCategories(): Observable<ApiListResponseModel<CategoryModel>> {
    return super.getList<CategoryModel>();
  }

  loadCategoryChildren(id: number): Observable<any>{
    return super.getDetail(id);
  }

  setCategories(categories: CategoryModel[]): void {
    this.categories = categories;
  }

  getCategories(): CategoryModel[]{
    return this.categories;
  }

  getCategory(id: number): CategoryModel{
    return this.categories.find(c => c.id === id);
  }
}
