import { Injectable } from '@angular/core';
import {lifecycleHookToNodeFlag} from '@angular/compiler/src/view_compiler/provider_compiler';
import {TranslateService} from '@ngx-translate/core';
import {GuruApiToursService} from '../../guru-api/guru-api-tours.service';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  langChanged = new Subject<string>();
  lang: string;
  languages: string[];

  constructor(private translate: TranslateService,
              private guruApiTourService: GuruApiToursService) {
  }

  get language(): string{
    return this.lang;
  }

  set language(lang: string){
    if(this.lang !== lang) {
      this.lang = lang;
      this.translate.use(this.lang);
      this.guruApiTourService.resetTours();
      this.langChanged.next(lang);
    }
  }

  getLanguages(): string[]{
    return this.languages;
  }

  setLanguages(languages: string[]): void{
    this.languages = languages;
  }

  isCurrentLang(lang: string): boolean{
    return lang === this.language;
  }

  didLangChange(): Observable<string>{
    return this.langChanged.asObservable();
  }
}
